import React, { useRef } from 'react';
import { Button } from 'primereact/button';
import { OverlayPanel } from 'primereact/overlaypanel';
import { ListBox } from 'primereact/listbox';

const MapStyleControl = ({ styles, onChange, currentStyle }) => {
  const op = useRef(null);

  const itemTemplate = (option) => {
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div
          style={{
            border: '1px solid #ccc',
            borderRadius: '5px',
            backgroundImage: `url(${option.thumbnail})`,
            backgroundSize: 'cover',
            width: '80px',
            height: '50px',
            marginRight: '10px',
          }}
        ></div>
        <div>{option.label}</div>
      </div>
    );
  };

  const selectedStyle = styles.find((style) => style.value === currentStyle);

  return (
    <div className="map-style-control">
      <Button
        onClick={(e) => op.current.toggle(e)}
        aria-haspopup
        aria-controls="overlay_panel"
        tooltip="Select Map Style"
        tooltipOptions={{ position: 'left' }}
        className="p-button-secondary"
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          padding: '6px',
        }}
      >
        <div
          style={{
            borderRadius: '5px',
            backgroundImage: `url(${selectedStyle.thumbnail})`,
            backgroundSize: 'cover',
            width: '70px',
            height: '40px',
            marginBottom: '5px',
          }}
        ></div>
        <div>
          {/* <i className="pi pi-map mr-1" style={{ fontSize: '1rem' }}></i> */}
          Map Style</div>
      </Button>
      <OverlayPanel ref={op} id="overlay_panel" dismissable>
        <ListBox
          value={currentStyle}
          options={styles}
          onChange={(e) => {
            onChange(e.value);
            op.current.hide();
          }}
          optionLabel="label"
          itemTemplate={itemTemplate}
          style={{ width: '220px' }}
        />
      </OverlayPanel>
    </div>
  );
};

export default MapStyleControl;